import React from 'react';
import { Layout } from '../components/layout/layout';
import { SEO } from '../components/layout/seo';
import { useConfig } from '../hooks/useConfig';
import { createUseStyles } from 'react-jss';
import { ProductGrid } from '../components/shop/productGrid';
import { graphql } from 'gatsby';
import { SubpageHeader } from '../components/layout/subpageHeader';

const useStyles = createUseStyles((theme) => ({}));

export default function Shop({
  location: { pathname },
  data: {
    allMarkdownRemark: { nodes }
  }
}) {
  const {
    mainSEO: { title, description, keywords }
  } = useConfig();
  const classes = useStyles();
  return (
    <Layout
      header={<SubpageHeader title="Sklep internetowy - produkty" />}
      slug={pathname}
      maxWidth={1600}
    >
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={pathname}
      />
      <ProductGrid products={nodes} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fields: { collection: { eq: "products" } } }) {
      nodes {
        html
        excerpt
        fields {
          slug
        }
        frontmatter {
          photos {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          guid
          name
          price
        }
      }
    }
  }
`;
