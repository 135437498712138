import React from 'react';
import { createUseStyles } from 'react-jss';
import { PictureCard } from '../utility/pictureCard';
import { Link } from 'gatsby';
import { SnipcartButton } from './snipCartButton';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { useYellowButton } from '../../hooks/useYellowButton';
import { formatter } from '../../utils/currencyFormatter';

const useStyles = createUseStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: ['var(--font-size-xxl)', '1rem']
  },
  price: {
    fontSize: 'var(--font-size-md)'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  description: {
    fontSize: 'var(--font-size-md)'
  }
});

export const Product = ({
  name,
  slug,
  guid,
  price,
  contentClass,
  ...other
}) => {
  const classes = useStyles();
  const buttonClass = useYellowButton();

  return (
    <PictureCard
      contentClass={classNames(classes.content, contentClass)}
      imageStyle={{ objectPosition: 'left top' }}
      {...other}
    >
      <div>
        <div className={classes.price}>{`Cena: ${formatter.format(
          price
        )}`}</div>
        <div className={classes.buttons}>
          <Link to={slug}>
            <Button className={buttonClass}>Opis</Button>
          </Link>
          <SnipcartButton guid={guid} name={name} price={price} />
        </div>
      </div>
    </PictureCard>
  );
};
