import React from 'react';
import { createUseStyles } from 'react-jss';
import { Product } from './product';
import { prependSlashIfAbsent } from '../../utils/url';
import { Link } from 'gatsby';

const useStyles = createUseStyles((theme) => ({
  grid: {
    maxWidth: 1400,
    display: 'grid',
    justifyContent: 'center',
    margin: [0, 'auto'],
    gridTemplateColumns: 'repeat( auto-fit, 400px )',
    gridAutoRows: 600,
    gap: '2vw'
  },
  container: {
    margin: [['var(--size-4x)', '20px']]
  },
  image: {},
  content: {},
  [`@media (max-width: ${theme.breakPoints.smMax})`]: {
    image: {
      height: '40vw'
    },
    content: {
      height: 'auto'
    }
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      textDecoration: 'none',
      color: 'black'
    },
    '&:hover img': {
      transform: 'scale(1.15)'
    }
  }
}));

export const ProductGrid = ({ products }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        {products.map(({ excerpt, frontmatter, fields: { slug } }) => (
          <Link to={prependSlashIfAbsent(slug)} className={classes.link}>
            <Product
              imageClass={classes.image}
              contentClass={classes.content}
              {...frontmatter}
              description={excerpt}
              key={frontmatter.guid}
              coverImage={frontmatter.photos[0]}
              title={frontmatter.name}
              slug={slug}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
